import JSBI from 'jsbi'
import { ChainId } from '../enums'

export * from './addresses'
export * from './numbers'

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}

export const CURRENCY_SYMBOL: { [chainId: number]: string } = {
  [ChainId.BSC_MAINNET]: 'BNB',
  [ChainId.BSC_TESTNET]: 'TBNB',
  [ChainId.HECO_MAINNET]: 'HT',
  [ChainId.HECO_TESTNET]: 'HTT',
}
