import { AddressMap } from '../types'
import { ChainId } from '../enums'

export const USDT_ADDRESS: AddressMap = {
  [ChainId.BSC_MAINNET]: '0x55d398326f99059fF775485246999027B3197955',
  [ChainId.BSC_TESTNET]: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
  [ChainId.HECO_MAINNET]: '0xa71EdC38d189767582C38A3145b5873052c3e47a',
  [ChainId.HECO_TESTNET]: '0x04F535663110A392A6504839BEeD34E019FdB4E0',
}

export const USDC_ADDRESS: AddressMap = {
  [ChainId.BSC_MAINNET]: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  [ChainId.BSC_TESTNET]: '0x16227D60f7a0e586C66B005219dfc887D13C9531',
  [ChainId.HECO_MAINNET]: '0x9362Bbef4B8313A8Aa9f0c9808B80577Aa26B73B',
  [ChainId.HECO_TESTNET]: '0xd459Dad367788893c17c09e17cFBF0bf25c62833',
}

export const USD_ADDRESS: AddressMap = {
  [ChainId.BSC_MAINNET]: USDC_ADDRESS[ChainId.BSC_MAINNET],
  [ChainId.BSC_TESTNET]: USDC_ADDRESS[ChainId.BSC_TESTNET],
  [ChainId.HECO_MAINNET]: USDC_ADDRESS[ChainId.HECO_MAINNET],
  [ChainId.HECO_TESTNET]: USDC_ADDRESS[ChainId.HECO_TESTNET],
}

export const FACTORY_ADDRESS: AddressMap = {
  [ChainId.BSC_MAINNET]: '0x738B815eaDD06E0041b52B0C9d4F0d0D277B24bA',
  [ChainId.BSC_TESTNET]: '0x508b60119a1538915C5b0329607D041Da6D14700',
  [ChainId.HECO_MAINNET]: '0x2216574F455CCc9E5FF9384Bbbd24e47c569CF67',
  [ChainId.HECO_TESTNET]: '0x6e315D2e71282f5e64C87baBe5F2C67A3FBE291c',
}

export const INIT_CODE_HASH: AddressMap = {
  [ChainId.BSC_MAINNET]: '0x8f79991c4fa4c79a3d3f4d7109ec898802f55f2753ddb2f14246dfd2945f561f',
  [ChainId.BSC_TESTNET]: '0xd1d4ce14359a832a06ffe21d1ca047cfdb07029c6489e590327254c8f18876b8',
  [ChainId.HECO_MAINNET]: '0x2c67a77f162bb8eb846230d0556e1da94380329da514631249cb854687a6ba92',
  [ChainId.HECO_TESTNET]: '0x03975dedea65e165e3f916da6674b5fbd80df0c4413ca0d2894ef9e433d20418',
}

export const ROUTER_ADDRESS: AddressMap = {
  [ChainId.BSC_MAINNET]: '0x61ee4e3BA0eC02eFb83355557d3055E26F315056',
  [ChainId.BSC_TESTNET]: '0xa4d70D348c6966532B0244fb769027Ebdab4151b',
  [ChainId.HECO_MAINNET]: '0xb26B4d59FB87D726Aef64933040ccf7009e7DEDa',
  [ChainId.HECO_TESTNET]: '0x78093c93e50549b3f3414f4ed81Fb735c928c555',
}

export const DAO_ADDRESS: AddressMap = {
  [ChainId.BSC_MAINNET]: '0xc096332CAacF00319703558988aD03eC6586e704',
  [ChainId.BSC_TESTNET]: '0xdb5D970F03bfD19c1E51D57BcEd114BC35A0808f',
  [ChainId.HECO_MAINNET]: '0xc096332CAacF00319703558988aD03eC6586e704',
  [ChainId.HECO_TESTNET]: '0xd2f169c79553654452a3889b210AEeF494eB2374',
}

export const WETH9_ADDRESS: AddressMap = {
  [ChainId.BSC_MAINNET]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  [ChainId.BSC_TESTNET]: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  [ChainId.HECO_MAINNET]: '0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F',
  [ChainId.HECO_TESTNET]: '0x7aF326B6351C8A9b8fb8CD205CBe11d4Ac5FA836',
}

export const MULTICALL_ADDRESS: AddressMap = {
  [ChainId.BSC_MAINNET]: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  [ChainId.BSC_TESTNET]: '0xC4dBD6c4A4d063A6A4267c21159Ee0d671000d6b',
  [ChainId.HECO_MAINNET]: '0x4ABC7AbEFDc7442F517A5B895b006cB0DDEEB368',
  [ChainId.HECO_TESTNET]: '0x438812b750dd37d7A709Dd0Ac95e32ABa23cf18e',
}
